import React from "react"
import Player from "../../../blocks/en/player"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  player: playersYaml(slug: { eq: "maksimov" }) {
    ...PlayerInfoEn
  }
  stats: allStatsYaml {
    nodes {
      key
      value_en
      average
    }
  }
}`

export default class Maksimov extends Player {
  renderDescription() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Powerful attacker, with good speed for his height. Coordinated when working with the ball, focused on high balls, opens well in the penalty area.</p>
          <p>Can do a first touch to the near partner, so he can be used both as a target man and as an second striker. Has a cultured shot from the middle and long distances, if necessary, can also shoot with his left foot.</p>
          <p>Able to press the defender after a loss, keeps his concentration. Stands well on his feet and does not lose speed in a physical duel.</p>
          <div className="video">
            <iframe
              title={ this.fullName() }
              src="https://www.youtube.com/embed/ZtDLQKa0of4"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </div>
          <p>In the 2017/18 season hold for a significant period the first place of Europa League goalscorers by scoring 7 goals for Trakai in the qualification round.</p>
          <img src="/images/players/maksimov/photo1.jpg" alt={ this.fullName() } />
        </div>
      </div>
    )
  }
}